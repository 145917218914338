import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/global.scss';
import './src/styles/locale-switcher.scss';
import CookieConsenter from './src/util/cookietools';

const cookieUtil = CookieConsenter();
window.threewireConsent = true;
// initializeCookieHandling();

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const newPath = location.pathname;
  const oldPath = (prevLocation?prevLocation.pathname:null);
  if (newPath!==oldPath) {
    const pagePath = (location ? (location.pathname + location.search + location.hash) : undefined);
    updateTrackingScripts(window.threewireConsent, pagePath);
  }
}

/*
function initializeCookieHandling() {
  cookieUtil.pollForCookie(1000).then(function(value) {
    window.threewireConsent = value;
  }).catch(function(error) {
    window.threewireConsent = false;
  });
  cookieUtil.addConsentListener(setConsent, 1000).catch(function(error) {
    console.log('Unable to set consent listener!');
  });
}

function setConsent(isConsented) {
  window.threewireConsent = isConsented;
  updateTrackingScripts(isConsented);
}
*/

function updateTrackingScripts(isConsented, pagePath) {
  const trackingTag = document.getElementById('threewire');
  if (isConsented) {
    if (trackingTag) trackingTag.remove();
    cookieUtil.addScript('threewire', '/threewire.js');
    if (pagePath) cookieUtil.utm.init(pagePath);
  } else if (trackingTag) {
    trackingTag.remove();
    cookieUtil.utm.clear();
    window.location.reload(true); // Reload page from server to refresh window object
  }
}
