/**
 * CookieConsenter provides for querying cookie consent and listening for consent changes.
 */
import cookieMgr from 'js-cookie';
const MAIN_COOKIE = 'OptanonConsent';
const browserEnv = (typeof window !== 'undefined');

export default function CookieConsenter(pagePath) {
  if (!pagePath) pagePath = '/';
  if (!browserEnv) {
    return {
      queryConsent: function() {return true;},
      pollForCookie: function() {return true;},
      addConsentListener: function() {return false;}
    };
  }

  const TARGETING_COOKIES = {
    id: 'C0004',
    names: ['_ga', '_gid', '_gat_gtag_UA_206376107_2', '_fbp', 'fr']
  };

  const utm = PageParamsTracker('UTM_INFO', ['utm_source', 'utm_medium', 'utm_content', 'utm_campaign', 'mpt']);

  // Return the optional cookie names (required cookies start with 'Optanon')
  function getCookieNamesByConfig(config) {
    return document.cookie.split('; ')
      .map(pair => pair.split('=')[0])
      .filter(item => config.names.includes(item));
  }

  function removeTargetingCookies(cookiePath) {
    const targetingCookies = getCookieNamesByConfig(TARGETING_COOKIES);
    if (targetingCookies.length>0) {
      const docDomain = document.domain.substr(document.domain.indexOf('.'));
      targetingCookies.forEach(name => { cookieMgr.remove(name, {path: cookiePath || pagePath, domain: docDomain}); });
    }
  }

  /**
   * Search for Onetrust cookie and parse it in order to determine consent.
   * @param function - optional callback to which consent is passed as parameter
   * @return boolean
   */
  function updateConsent(callback) {
    const ok = getConsentFromCookie();
    if (!ok) removeTargetingCookies();
    if (callback) callback(ok);
    return ok;
  }

  /**
   * Determine cookie consent status.
   * No cookie = Yes
   * Cookie exists and contains C0004 = Yes
   * Cookie exists and has NO C0004 = No
   * EU user -> opt-in NOT opt-out
   * @return boolean
   */
  function queryConsent() {
    if (browserEnv) return updateConsent();
    return true;
  }

  /**
   * Parse a cookie value for a specific pattern.
   * Example excerpt: "...landingPath=NotLandingPage&groups=C0004:1,C0001:1,C0002:1&geolocation=US;FL&..."
   * @param string - an encoded cookie string
   * @return boolean - whether or not the cookie string shows that targeting cookies (C0004) are consented (:1)
   */
  function getConsentFromCookie() {
    if (!browserEnv) return true;
    const cookieValue = cookieMgr.get(MAIN_COOKIE);
    if (!cookieValue) return false;
    const decoded = window.decodeURIComponent(cookieValue);
    // Parse the cookie until we find the targeting group value; a parse failure indicates to load script
    const groups = decoded.split('groups=')[1];
    if (!groups) return false;
    const targeting = groups.split('&')[0].split(',').find(item => item.startsWith(TARGETING_COOKIES.id));
    const targetingValue = targeting.split(':')[1];
    const ok = (targetingValue ? Boolean(Number(targetingValue)) : false);
    return ok;
  }

  function pollForCookie(delay) {
    let counter = 0;
    const maxTry = 3;
    if (!delay) delay = 1000;
    return new Promise((resolve, reject) => {
      const intvId = setInterval(function() {
        counter++;
        const cookie = cookieMgr.get(MAIN_COOKIE);
        if (cookie) {
          clearInterval(intvId);
          const ok = getTargetingConsent(cookie);
          if (!ok) removeTargetingCookies();
          resolve(ok);
        } else if (counter>=maxTry) {
          clearInterval(intvId);
          removeTargetingCookies();
          // If no cookie found: US=accept, EU=revoke
          reject(false);
        }
      }, delay);
    });
  }

  function getTargetingConsent(cookieValue) {
    /*
    const decoded = window.decodeURIComponent(cookieValue);
    Parse the cookie until we find the targeting group value; a parse failure indicates to load script
    const groups = decoded.split('groups=')[1];
    if (!groups) return true;
    const targeting = groups.split('&')[0].split(',').find(item => item.startsWith(TARGETING_COOKIES.id));
    const targetingValue = targeting.split(':')[1];
    const ok = (targetingValue ? Boolean(Number(targetingValue)) : true);
    */
    return true;
  }

  /**
   * Check existence of Onetrust SDK and attach consent listener.
   * @param function - a setter function for cookie consent
   * @param int - milliseconds to wait between retries
   * @return boolean
   */
  async function addConsentListener(hasConsentFn, delay) {
    function consentListener(e) { updateConsent(hasConsentFn); }
    if (window.OneTrust) {
      window.OneTrust.OnConsentChanged(consentListener);
      return true;
    }
    else {
      if (!delay) delay = 2000; // milliseconds
      const maxTry = 3;
      return new Promise((resolve, reject) => {
        let tries = 0;
        const timerId = setInterval(function() {
          tries++;
          if (window.OneTrust) {
            window.OneTrust.OnConsentChanged(consentListener);
            clearInterval(timerId);
            resolve(true);
          }
          if (tries>=maxTry) {
            clearInterval(timerId);
            reject(false);
          }
        }, delay);
      });
    }
  }

  function addScript(id, path) {
    const scriptTag = document.createElement('script');
    scriptTag.id = id;
    scriptTag.src = path;
    document.body.appendChild(scriptTag);
  }

  return { queryConsent, pollForCookie, addConsentListener, addScript, utm };
};

function PageParamsTracker(cookieName, cookieFields) {
  /**
   * Parse url into a delimited string of parameters, filtering out any unregistered values.
   * @param string - the query string parameters received from the referrer
   * @return string
   */
  function parseParams(source) {
    if (!source) return '';
    const parts = source.split('?');
    if (parts.length<2) return '';
    return parts[1].split('&').filter(pair => cookieFields.includes(pair.split('=')[0])).join('|');
  }

  const docDomain = (document.domain==='localhost' ? 'localhost' : document.domain.substr(document.domain.indexOf('.')).replace('/', ''));

  function init(pagePath) {
    const originalUtm = parseParams(pagePath);
    cookieMgr.set(cookieName, (originalUtm || get()));
  }

  function clear() {
    if (get()) cookieMgr.remove(cookieName, {path: '/', domain: docDomain});
    if (get()) cookieMgr.set(cookieName, '');
  }

  function get() { return cookieMgr.get(cookieName); }

  return { get, init, clear };
}
